<template lang="html">
  <section class="common">
    <h1>common Component</h1>
  </section>
</template>

<script lang="js">
import companyConfig from '@/company_config.json';
import { LOGOUT } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import * as XLSX from "xlsx/xlsx.mjs";

export default {
    name: 'common',
    props: [],
    mounted() {

    },
    data() {
        return {

        }
    },
    methods: {
        LogoutUser() {
            console.log('LogoutUser called');
            localStorage.clear();
            this.$session.destroy();
            this.$store.dispatch(LOGOUT).then(() => this.$router.push("/login"));
        },
        test() {
            // alert('test');
            console.log('test function is called');
        },
        redirectPreviousPage() {
            this.$router.go(-1);
        },
        getCurrentDate(){
            var currentDate = new Date().toISOString().slice(0, 10);
            console.log({ currentDate });
            return currentDate;
        },
        getCurrentTime(){
            var currentTime = new Date();
            var hours = currentTime.getHours().toString().padStart(2, "0");
            var minutes = currentTime.getMinutes().toString().padStart(2, "0");
            var formattedTime = `${hours}:${minutes}`;
            return formattedTime;
        },
        toast(title, message) {
            var append = false;
            var toaster = "b-toaster-top-right";
            var variant = "danger";
            var icon = "error";
            switch (title) {
                case 'success':
                    icon = "success";
                    variant = "success";
                    break;
                case 'error':
                    variant = "danger";
                    icon = "error";
                    break;
                case 'warning':
                    variant = "warning";
                    icon = "warning";
                    break;
                default:
                    variant = "info";
                    icon = "info";
                    break;
            }
            console.log('icon='+icon);

            this.$bvToast.toast(`${message}`, {
                title: `${title}`,
                toaster: toaster,
                appendToast: append,
                variant: variant,
                solid: true,
            });

           /*
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: icon,
                title: message,
            });
            */
        },
        sweetAlert(title, message, timer) {
            console.log("sweetAlert called");
            var icon = '';
            switch (title) {
                case 'success':
                    icon = "success";
                    break;
                case 'error':
                    icon = "error";
                    break;
                default:
                    icon = "info";
                    break;
            }
            console.log('icon='+icon);
            var options = {
                title: "",
                text: message,
                icon: icon
            };
            if (timer) {
                options.timer = 1500;
                options.timerProgressBar = true;
                options.showConfirmButton = false;
            }else{
                options.showConfirmButton = true;
            }
            Swal.fire(options);
        },
        checkUserRights(UI, RoleId){
          console.log('checkUserRights called');
          console.log('UI='+UI+', RoleId='+RoleId);
          // UI => 1=Login Redirection; 2=Awards Individual; 3=LO; 4=Assessor; 5=Super Admin
          // RoleId => 1=Admin; 2=ZO; 3=LO; 4=Assessor; 5=Super Admin
          RoleId = parseInt(RoleId)
          var result = false
          switch (UI) {

            case 1:
              var Roles = [1, 2, 3, 5, 7]
              break;

            case 2:
              var Roles = [1, 2, 3, 5]
              break;

            default:
              var Roles = []
              break;
          }
          result = Roles.includes(RoleId);
          console.log('result='+result);
          return result
        },
        getDecimalNumber(value, decimals){
          return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
        },
        changeToUpper(inputBox) {
            // console.log('changeToUpper called');
            // var inputValue = this[inputBox];
            // console.log('inputBox='+inputBox+', inputValue='+inputValue);
            this[inputBox] = this[inputBox].toUpperCase();
        },
        getActiveStatusColor(status) {
            if (status == 'Not completed' || status == 'Pending' || status == 'Process' || status == 'Returned' || status == 'Disabled'|| status == 'Yet to complete' || status == 'Not eligible' || status == 'Suspended' || status == 'Waiting For Approval' || status == 'Waiting For Confirmation' || status == 'ZP Approval Pending' || status == 'NHQ Approval Pending') return "warning"
            if (status == 'Active' || status == 'Approved' || status == 'Used' || status == 'Enabled') return "success"
            if (status == 'Rejected' || status == 'Not scored' || status == 'Dissaffiliated' || status == 'Inactive' || status == 'Cancelled') return "#ff0000"
            if (status == 'Completed' || status == 'Closed' || status == 'Scored' || status == 'Provisional') return "primary"
            return "dark"
        },
        pageRedirect(params, url) {
            console.log('pageRedirect function is called');
            console.log('url=' + url + ', params=' + JSON.stringify(params));
            var n1 = Object.keys(params).length;
            console.log('n1=' + n1);
            // this.pageLoadingOn();
            if (n1 > 0) {
                this.$router.push({
                    path: url,
                    query: params,
                });
            } else {
                if (url != "") {
                    this.$router.push(url);
                } else {
                    this.$router.go();
                }
            }
            // this.pageLoadingOff();
        },
        redirectPreviousPage() {
          this.$router.go(-1);
        },
        successMessage(message) {
          console.log('successMessage called'+message)
          var options = {
            theme: "bubble",
            position: "top-center",
            type: 'success',
            iconPack: 'mdi',
            icon: 'mdi-check-circle',
            action : {
              text: 'close',
              // iconPack: 'mdi',
              // icon: 'mdi-close-circle',
              onClick : (e, toastObject) => {
                toastObject.goAway(0);
              }
            }
          }
          this.$toasted.show(message, options)
        },
        errorMessage(message) {
          console.log('errorMessage called'+message)
          var options = {
            theme: "bubble",
            position: "top-center",
            type: 'error',
            iconPack: 'mdi',
            icon: 'mdi-close-circle',
            action : {
              text: 'close',
              // iconPack: 'mdi',
              // icon: 'mdi-close-circle',
              onClick : (e, toastObject) => {
                toastObject.goAway(0);
              }
            }
          }
          this.$toasted.show(message, options)

        },
        pageLoadingOn() {
            this.$vs.loading({
                type: 'material',
            });
        },
        pageLoadingOff() {
            this.$vs.loading.close();
        },
        getFileMimeType(file, fallback = null) {
            console.log('getFileMimeType called');
            const byteArray = new Uint8Array(file).subarray(0, 4);
            let header = "";
            for (let i = 0; i < byteArray.length; i++) {
                header += byteArray[i].toString(16);
            }
            var MimeType=null;
            console.log({header});
            switch (header) {
                case "89504e47":
                    MimeType="image/png";
                    break;
                case "47494638":
                    MimeType="image/gif";
                    break;
                case "ffd8ffe0":
                case "ffd8ffe1":
                case "ffd8ffe2":
                case "ffd8ffe3":
                case "ffd8ffe8":
                    MimeType="image/jpeg";
                    break;
                default:
                    break;
            }
            console.log({MimeType});
            return MimeType;
        },
        selectListActiveStatus() {
            var result = [{
                    value: '',
                    text: '--Select Status--'
                },
                {
                    value: "0",
                    text: 'InActive'
                },
                {
                    value: "1",
                    text: 'Active'
                },
            ];
            return result;
        },
        selectListInvoiceStatus() {
            var result = [{
                    value: '',
                    text: '--Select Status--'
                },
                {
                    value: "0",
                    text: 'All'
                },
                {
                    value: "1",
                    text: 'Active'
                },
                {
                    value: "2",
                    text: 'Pending'
                },
                {
                    value: "3",
                    text: 'Rejected'
                },
                {
                    value: "4",
                    text: 'Cancelled'
                },
            ];
            return result;
        },
        selectListCreditDebit() {
            var result = [{
                    value: '',
                    text: '--Select Type--'
                },
                {
                    value: '1',
                    text: 'Credit'
                },
                {
                    value: '2',
                    text: 'Debit'
                },
            ];
            return result;
        },
        selectListPaymentType() {
            var result = [{
                    value: '',
                    text: '--Select Type--'
                },
                {
                    value: 1,
                    text: 'Cash'
                },
                {
                    value: 2,
                    text: 'Bank'
                },
                {
                    value: 3,
                    text: 'Wallet'
                },
            ];
            return result;
        },
        selectListTrueFalse() {
            var result = [{
                    value: '',
                    text: '--Select--'
                },
                {
                    value: 1,
                    text: 'Yes'
                },
                {
                    value: 2,
                    text: 'No'
                },
            ];
            return result;
        },
        selectListYesNo() {
            var result = [{
                    value: '',
                    text: '--Select--'
                },
                {
                    value: "0",
                    text: 'No'
                },
                {
                    value: "1",
                    text: 'Yes'
                },
            ];
            return result;
        },
        selectListPartyCategory() {
            var result = [{
                    value: '',
                    text: '--Select Category--'
                },
                {
                    value: 1,
                    text: 'Supplier'
                },
                {
                    value: 2,
                    text: 'Customer'
                },
                {
                    value: 3,
                    text: 'Employees'
                },
            ];
            return result;
        },
        // Used in LocalCessTypeAddNew,
        selectListCessCalculationMethod() {
            var result = [{
                    value: '',
                    text: '--Select Type--'
                },
                {
                    value: 1,
                    text: 'Add Before Subtotal'
                },
                {
                    value: 2,
                    text: 'Add After Subtotal'
                },
            ];
            return result;
        },
        selectListFinancialYear() {
            var result = [{
                    value: '',
                    text: '--Select--'
                },
                {
                    value: '2019-2020',
                    text: '2019-2020'
                },
                {
                    value: '2020-2021',
                    text: '2020-2021'
                },
                {
                    value: '2021-2022',
                    text: '2021-2022'
                },
                {
                    value: '2022-2023',
                    text: '2022-2023'
                },
            ];
            return result;
        },
        selectPriceRuleType() {
            var result = [{
                    value: '',
                    text: '--Select Type--'
                },
                {
                    value: '1',
                    text: 'Profit Percentage'
                },
                {
                    value: '2',
                    text: 'Fixed Profit'
                },
                {
                    value: '3',
                    text: 'MRP'
                },
                {
                    value: '4',
                    text: 'Fixed Price'
                },
            ];
            return result;
        },
        selectListIncludedYesNo() {
            var result = [{
                    value: '',
                    text: '--Select--'
                },
                {
                    value: '1',
                    text: 'Yes'
                },
                {
                    value: '2',
                    text: 'No'
                },
            ];
            return result;
        },
        selectListImportModule() {
            var result = [{
                    value: '',
                    text: '--Select Module--'
                },
                {
                    value: '1',
                    text: 'New Product'
                },
            ];
            return result;
        },
        selectListLedgerScope() {
            var result = [{
                    value: '',
                    text: '--Select--'
                },
                {
                    value: '1',
                    text: 'Current Branch'
                },
                {
                    value: '2',
                    text: 'Common to All'
                },
                {
                    value: '3',
                    text: 'Create to All'
                },
            ];
            return result;
        },
        onlyNumber($event) {
            console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },
        CalculatedNetPrice(SellingPrice, GstSlab) {
            var netPrice = 0
            console.log('getReverseCalculatedAmount is called');
            console.log('SellingPrice=' + SellingPrice + ', GstSlab=' + GstSlab);
            var GstAmount = parseFloat(SellingPrice) - (parseFloat(SellingPrice) * (100 / (100 + (parseFloat(GstSlab)))));
            GstAmount = this.getDecimalNumber(GstAmount, 2)
            netPrice = parseFloat(SellingPrice) - parseFloat(GstAmount);
            netPrice = this.getDecimalNumber(netPrice, 2)
            console.log('GstAmount=' + GstAmount + ', netPrice=' + netPrice);
            return netPrice;
        },
        logout() {

            console.log('logout function is called from common vue');

            // this.$vs.loading({ type:'material', });

            var server_url = companyConfig.apiURL;
            console.log('server_url=' + server_url);

            var url = server_url + 'logout';
            console.log('url=' + url);

            const token = this.$session.get('token');
            console.log('token=' + token);

            var upload = {
                'token': token,
            };
            console.log('upload=' + JSON.stringify(upload));

            this.$http({
                    url: url,
                    method: 'POST',
                    data: upload,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
                .then(function (response) {
                    // this.$vs.loading.close();
                    console.log('response=' + JSON.stringify(response));
                })
                .catch(function (error) {
                    console.log('error=' + error);
                })
            console.log('now you are going to logout');
            this.$session.destroy();
            this.$router.push('/');
        },
        getSelectBoxOptions(source, destination, condition, url) {

            console.log('getSelectBoxOptions function is called');

            console.log('source=' + source + ', destination=' + destination + ', condition=' + JSON.stringify(condition) + ', url=' + url);

            var server_url = companyConfig.apiURL;
            console.log('server_url=' + server_url);

            url = server_url + url;
            console.log('url=' + url);

            var token = this.$session.get('token');
            console.log('token=' + token);

            token = token == (null || undefined) ? 0 : token

            // this.pageLoadingOn();

            const thisIns = this;
            var output = "";
            var flag = 0;
            var records = [];
            var totalRecords = 0;

            var sourceValue = thisIns[source];
            console.log('sourceValue=' + sourceValue);
            /*
            var additional = {};
            var n1=condition.length;
            console.log('n1='+n1);
            for (let index = 0; index < n1; index++) {
              var element = condition[index];
              var temp = thisIns[element];
              console.log('index='+index+', element='+element+', temp='+temp);
              if (temp != '') {
                additional[element]=temp;
              }
            }
            console.log('additional='+JSON.stringify(additional));
            */
            var upload = {
                // 'token': token,
                'source': sourceValue,
                'additional': condition,
            };
            console.log('upload=' + JSON.stringify(upload));
            /*
            var conditionValue = condition == '' ?  '' : thisIns[condition];
            console.log('conditionValue='+conditionValue);
            */
            this.$http({
                    url: url,
                    method: 'POST',
                    data: upload,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: "Bearer " + token,
                    }
                })
                .then(function (response) {
                    console.log('response=' + JSON.stringify(response));
                    output = response.data.output;
                    flag = response.data.flag;
                    totalRecords = records.length;
                    console.log('output=' + output + ', flag=' + flag + ', totalRecords=' + totalRecords);
                    // thisIns.pageLoadingOff();
                    if (flag == 1) {
                        records = response.data.records;
                        thisIns[destination] = records;
                    } else {
                        // thisIns.errorMessage(output);
                    }
                })
                .catch(function (error) {
                    console.log('error=' + error);
                })

        },
        getPageDetails(destination, defaultValue, url, condition) {

            console.log('getPageDetails function is called');

            console.log('destination=' + destination +', url=' + url + ', condition=' + JSON.stringify(condition));

            var server_url = companyConfig.apiURL;
            console.log('server_url=' + server_url);

            url = server_url + url;
            console.log('url=' + url);

            var token = this.$session.get('token');
            console.log('token=' + token);

            token = token == (null || undefined) ? 0 : token

            // this.pageLoadingOn();

            const thisIns = this;
            var output = "";
            var flag = 0;
            var records = defaultValue;
            var totalRecords = 0;

            this.$http({
                    url: url,
                    method: 'POST',
                    data: condition,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: "Bearer " + token,
                    }
                })
                .then(function (response) {
                    console.log('response=' + JSON.stringify(response));
                    output = response.data.output;
                    flag = response.data.flag;
                    console.log('output=' + output + ', flag=' + flag);
                    // thisIns.pageLoadingOff();
                    if (flag == 1) {
                        records = response.data.records;
                        thisIns[destination] = records;
                    } else {
                        thisIns[destination] = records;
                        // thisIns.toast("error", output);
                    }
                })
                .catch(function (error) {
                    console.log('error=' + error);
                    thisIns[destination] = records;
                })

        },
        getLogoutResponse(destination, defaultValue, url, condition, token) {

            console.log('getPageDetails function is called');

            console.log('destination=' + destination +', url=' + url + ', condition=' + JSON.stringify(condition));

            var server_url = companyConfig.apiURL;
            console.log('server_url=' + server_url);

            url = server_url + url;
            console.log('url=' + url);

            // this.pageLoadingOn();

            const thisIns = this;
            var output = "";
            var flag = 0;
            var records = defaultValue;
            var totalRecords = 0;

            this.$http({
                    url: url,
                    method: 'POST',
                    data: condition,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: "Bearer " + token,
                    }
                })
                .then(function (response) {
                    console.log('response=' + JSON.stringify(response));
                    output = response.data.output;
                    flag = response.data.flag;
                    console.log('output=' + output + ', flag=' + flag);
                    // thisIns.pageLoadingOff();
                    if (flag == 1) {
                        records = response.data.records;
                        thisIns[destination] = records;
                        thisIns.toast("success", output);
                    } else {
                        thisIns[destination] = records;
                        thisIns.toast("error", output);
                    }
                })
                .catch(function (error) {
                    console.log('error=' + error);
                    thisIns[destination] = records;
                })

        },
        getTableData(rows, url, where) {

            console.log('getTableData function is called');

            console.log('rows=' + rows + ', url=' + url + ', where=' + JSON.stringify(where));

            // this.pageLoadingOn();

            var server_url = companyConfig.apiURL;
            console.log('server_url=' + server_url);

            url = server_url + url;
            console.log('url=' + url);

            var token = this.$session.get('token');
            console.log('token=' + token);

            token = token == (null || undefined) ? 0 : token

            if (token == "") {
              // this.logout();
            }

            const thisIns = this;
            var output = "";
            var successTxt = "";
            var errorTxt = "";
            var flag = 0;
            var records = [];
            var totalRecords = 0;

            // var upload = { 'token': token, 'where': where };
            // var headers={ 'Content-Type': 'application/x-www-form-urlencoded' };

            var upload=where;
            var headers={
                'Content-Type': 'application/json',
                Authorization: "Bearer " + token,
            };

            console.log('upload=' + JSON.stringify(upload));

            this.$http({
                    url: url,
                    method: 'POST',
                    data: upload,
                    headers: headers
                })
                .then(function (response) {
                    console.log('response=' + JSON.stringify(response));
                    output = response.data.output;
                    flag = response.data.flag;
                    successTxt = response.data.success;
                    errorTxt = response.data.error;
                    console.log('output=' + output + ', flag=' + flag);
                    console.log('successTxt=' + successTxt + ', errorTxt=' + errorTxt);
                    // thisIns.pageLoadingOff();
                    if (flag == 1) {
                        records = response.data.records;
                        totalRecords = records.length;
                        console.log('totalRecords=' + totalRecords);
                        thisIns[rows] = records;
                        // thisIns.$parent[rows]=records;
                        // thisIns.successMessage(successTxt);

                    } else {
                        // thisIns.message=output
                        // thisIns.errorMessage(errorTxt);
                    }
                })
                .catch(function (error) {
                    console.log('error=' + error);
                })

        },
        fetchRecords(rows, url, where) {

            console.log('getTableData function is called');

            console.log('rows=' + rows + ', url=' + url + ', where=' + JSON.stringify(where));

            this.pageLoadingOn();

            var server_url = companyConfig.apiURL;
            console.log('server_url=' + server_url);

            url = server_url + url;
            console.log('url=' + url);

            const token = this.$session.get('token');
            console.log('token=' + token);

            if (token == "") {
                this.logout();
            }

            const thisIns = this;

            var upload = {
                'token': token,
                'where': where
            };

            console.log('upload=' + JSON.stringify(upload));

            this.$http({
                    url: url,
                    method: 'POST',
                    data: upload,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
                .then(function (response) {
                    console.log('response=' + JSON.stringify(response));
                    thisIns[rows] = response.data;
                    thisIns.pageLoadingOff();
                })
                .catch(function (error) {
                    console.log('error=' + error);
                })

        },
        addRecords(formData, url, redirect_url) {

            console.log('addRecords function is called');
            console.log('formData=' + JSON.stringify(formData) + ', url=' + url + ', redirect_url=' + redirect_url);

            var n1 = formData.length;
            console.log('n1=' + n1);

            var server_url = companyConfig.apiURL;
            console.log('server_url=' + server_url);

            url = server_url + url;
            console.log('url=' + url);

            const token = this.$session.get('token');
            console.log('token=' + token);

            // var result = {};

            if (n1 > 0) {

                const thisIns = this;

                var form = {};
                for (var index = 0; index < n1; index++) {
                    var element = formData[index];
                    console.log('index=' + index + ', element=' + element);
                    var formValue = thisIns[element];
                    console.log('formValue=' + formValue);
                    form[element] = formValue;
                }
                var n2 = Object.keys(form).length;
                console.log('n2=' + n2);
                console.log('form=' + JSON.stringify(form));

                var upload = {
                    'token': token,
                    'form': form
                };

                thisIns.pageLoadingOn();

                var output = "";
                var successTxt = "";
                var errorTxt = "";
                var flag = 0;

                this.$http({
                        url: url,
                        method: 'POST',
                        data: upload,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    })
                    .then(function (response) {
                        console.log('response=' + JSON.stringify(response));
                        output = response.data.output;
                        flag = response.data.flag;
                        output = response.data.output;
                        flag = response.data.flag;
                        console.log('output=' + output + ', flag=' + flag);
                        successTxt = response.data.success;
                        errorTxt = response.data.error;
                        console.log('successTxt=' + successTxt + ', errorTxt=' + errorTxt);

                        thisIns.pageLoadingOff();
                        if (flag == 1) {

                            for (var index = 0; index < n1; index++) {
                                var element = formData[index];
                                console.log('index=' + index + ', element=' + element);
                                // thisIns[element]='';
                            }
                            thisIns.successMessage(successTxt);
                            var params = {};
                            thisIns.pageRedirect(params, redirect_url);

                        } else {
                            thisIns.errorMessage(errorTxt);
                        }

                    })
                    .catch(function (error) {
                        console.log('error=' + error);
                    })

            }

        },
        getRecords(where, destinations, url) {

            console.log('getRecords function is called');
            console.log('where=' + JSON.stringify(where) + ', destinations=' + JSON.stringify(destinations) + ', url=' + url);

            var n1 = Object.keys(where).length;
            var n2 = destinations.length;
            console.log('n1=' + n1 + ', n2=' + n2);

            var server_url = companyConfig.apiURL;
            console.log('server_url=' + server_url);

            url = server_url + url;
            console.log('url=' + url);

            const token = this.$session.get('token');
            console.log('token=' + token);

            if (!this.$session.exists()) {
                console.log('your session is expired');
                this.$router.push('/logout');
            }
            // var result = {};

            if (n1 > 0) {

                this.pageLoadingOn();
                var output = "";
                var successTxt = "";
                var errorTxt = "";
                var flag = 0;

                const thisIns = this;

                var records = [];
                var totalRecords = 0;

                var upload = {
                    'token': token,
                    'where': where
                };

                console.log('upload=' + JSON.stringify(upload));

                this.$http({
                        url: url,
                        method: 'POST',
                        data: upload,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    })
                    .then(function (response) {
                        console.log('response=' + JSON.stringify(response));
                        output = response.data.output;
                        flag = response.data.flag;
                        console.log('output=' + output + ', flag=' + flag);
                        successTxt = response.data.success;
                        errorTxt = response.data.error;
                        console.log('successTxt=' + successTxt + ', errorTxt=' + errorTxt);

                        thisIns.pageLoadingOff();
                        if (flag == 1) {

                            records = response.data.records[0];
                            // totalRecords= records.length;
                            console.log('totalRecords=' + totalRecords);
                            console.log('records=' + JSON.stringify(records));
                            for (var index = 0; index < n2; index++) {

                                var element = destinations[index];
                                console.log('index=' + index + ', element=' + element);

                                var result = records[element];
                                console.log('result=' + result);

                                var search = records.hasOwnProperty(element);
                                console.log('search=' + search);

                                if (search) {
                                    thisIns[element] = result;
                                }

                            }
                            thisIns.successMessage(successTxt);

                        } else {
                            thisIns.errorMessage(errorTxt);
                        }

                    })
                    .catch(function (error) {
                        console.log('error=' + error);
                    })

            }

        },
        updateRecords(where, formData, url, redirect_url, params) {

            console.log('updateRecords function is called');
            console.log('where=' + JSON.stringify(where) + ', formData=' + JSON.stringify(formData) + ', url=' + url + ', params=' + JSON.stringify(params) + ', redirect_url=' + redirect_url);

            var n1 = Object.keys(where).length;
            var n2 = formData.length;
            console.log('n1=' + n1 + ', n2=' + n2);

            var server_url = companyConfig.apiURL;
            console.log('server_url=' + server_url);

            url = server_url + url;
            console.log('url=' + url);

            const token = this.$session.get('token');
            console.log('token=' + token);

            // var result = {};

            if (n1 > 0 && n2 > 0) {

                var form = {};
                for (var index = 0; index < n2; index++) {
                    var element = formData[index];
                    console.log('index=' + index + ', element=' + element);
                    var formValue = this[element];
                    console.log('formValue=' + formValue);
                    form[element] = formValue;
                }
                var n3 = Object.keys(form).length;
                console.log('n3=' + n3);
                console.log('form=' + JSON.stringify(form));

                this.pageLoadingOn();
                var upload = {
                    'token': token,
                    'where': where,
                    'form': form
                };

                console.log('upload=' + JSON.stringify(upload));

                const thisIns = this;
                var output = "";
                var successTxt = "";
                var errorTxt = "";
                var flag = 0;

                this.$http({
                        url: url,
                        method: 'POST',
                        data: upload,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    })
                    .then(function (response) {
                        console.log('response=' + JSON.stringify(response));
                        output = response.data.output;
                        flag = response.data.flag;
                        output = response.data.output;
                        flag = response.data.flag;
                        console.log('output=' + output + ', flag=' + flag);
                        successTxt = response.data.success;
                        errorTxt = response.data.error;
                        console.log('successTxt=' + successTxt + ', errorTxt=' + errorTxt);

                        thisIns.pageLoadingOff();
                        if (flag == 1) {

                            thisIns.successMessage(successTxt);
                            thisIns.pageRedirect(params, redirect_url);

                        } else {
                            thisIns.errorMessage(errorTxt);
                        }

                    })
                    .catch(function (error) {
                        console.log('error=' + error);
                    })

            }

        },
        deleteRecords(delete_where, url, destination, list_url, list_where) {

            console.log('deleteRecords function is called');
            console.log('delete_where=' + JSON.stringify(delete_where) + ', url=' + url + ', destination' + destination + ', list_url=' + list_url + ', list_where=' + JSON.stringify(list_where));

            var n1 = Object.keys(delete_where).length;
            console.log('n1=' + n1);

            var n2 = Object.keys(list_where).length;
            console.log('n2=' + n2);

            var server_url = companyConfig.apiURL;
            console.log('server_url=' + server_url);

            url = server_url + url;
            console.log('url=' + url);

            const token = this.$session.get('token');
            console.log('token=' + token);

            // var result = {};

            if (n1 > 0) {

                var upload = {
                    'token': token,
                    'where': delete_where
                };

                console.log('upload=' + JSON.stringify(upload));

                this.pageLoadingOn();
                const thisIns = this;
                var output = "";
                var successTxt = "";
                var errorTxt = "";
                var flag = 0;

                this.$http({
                        url: url,
                        method: 'POST',
                        data: upload,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    })
                    .then(function (response) {
                        console.log('response=' + JSON.stringify(response));
                        output = response.data.output;
                        flag = response.data.flag;
                        output = response.data.output;
                        flag = response.data.flag;
                        console.log('output=' + output + ', flag=' + flag);
                        successTxt = response.data.success;
                        errorTxt = response.data.error;
                        console.log('successTxt=' + successTxt + ', errorTxt=' + errorTxt);

                        thisIns.pageLoadingOff();
                        if (flag == 1) {
                            thisIns.successMessage(successTxt);
                            if (destination != '' && list_url != '') {
                                // thisIns.pageRedirect('', list_url);
                                thisIns.getTableData(destination, list_url, list_where);
                            }
                        } else {
                            thisIns.errorMessage(errorTxt);
                        }

                    })
                    .catch(function (error) {
                        console.log('error=' + error);
                    })

            }

        },
        getActionsPermissions(element, where){
          var url = 'api/users/get_actions_permissions';
          this.getTableData(element, url, where);

        },
        checkUserPermission(element, where){
          var url = 'api/users/check_user_permission';
          this.getTableData(element, url, where);

        },
        generateExcel(TableData, TableHeader, FileName, SheetName) {
        console.log("generateExcel is called");
        
        var data = [];
        TableData.forEach((value, index, self) => {
            var data1 = [];
            for (let index1 in TableHeader) {
            var value1 = TableHeader[index1];
            data1[index1] =
                self[index][value1] == null ||
                self[index][value1] == undefined
                ? ""
                : self[index][value1];
            }
            data.push(data1);
        });

        /* generate worksheet and workbook */
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, SheetName);

        /* create an XLSX file and try to save to Presidents.xlsx */
        XLSX.writeFile(workbook, FileName);
        }
        /*
        generatePDF(filename, pdfType, windowType, docDefinition) {
            var pdfMake = require('pdfmake/build/pdfmake.js');
            if (pdfMake.vfs == undefined) {
                var pdfFonts = require('pdfmake/build/vfs_fonts.js');
                pdfMake.vfs = pdfFonts.pdfMake.vfs;
            }
            // windowType: 1=Same window, 2=New window
            var win = windowType == 1 ? window : window.open('', '_blank');
            // pdfType: 1=Download, 2=Open, 3=Print

            if (pdfType == 1) {
                pdfMake.createPdf(docDefinition).download(filename + '.pdf');
            } else if (pdfType == 2) {
                pdfMake.createPdf(docDefinition).open({}, win);
            } else if (pdfType == 3) {
                pdfMake.createPdf(docDefinition).print({}, win);
            } else {
                var safari = navigator.userAgent.indexOf("Safari");
                console.log('safari=' + safari);
                if (safari != -1) {
                    pdfMake.createPdf(docDefinition).open({}, window.frames['printPdf']);
                    setTimeout(function () {
                        window.frames['printPdf'].focus();
                        window.frames['printPdf'].print();
                    }, 2000)
                } else {
                    pdfMake.createPdf(docDefinition).print({}, window.frames['printPdf']);
                }
                // pdfMake.createPdf(docDefinition).open({}, window);
            }

        }
        */
    },
    computed: {

    }
}
</script>

<style lang="scss" scoped>
.common {
  .iconSize {
    font-size: 15px;
  }
}
</style>
